<template>
  <TTView>
    <TTExample
      file="components/TTBreadcrumbs/examples/TTBreadcrumbsExample"
    />
  </TTView>
</template>

<script>
export default {
  name: 'Breadcrumbs',
};
</script>
